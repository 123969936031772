import axios from '@/plugins/axiosV2'

const http = axios(process.env.VUE_APP_API_CONDUCEF + '/configuracion');

export default {

    listar_configuraciones() {
        return http.get('/');
    },

    crear_configuracion(payload) {
        return http.post('/', payload);
    },

    actualizar_configuracion(id, payload) {
        return http.put(`/${id}`, payload);
    }
}