<template>
    <div>
        <Modal :options="{ width: '40vw' }">
            <form @submit.prevent="onSubmit">
                <div class="title">{{ !conf.id ? 'Crear configuración' : 'Actualizar configuración' }}</div>
                <div class="body">
                    <div class="justify-content-center">
                        <div class="col-md-12 my-3">
                            <label for="nombre">Nombre <strong class="text-danger"> *</strong></label>
                            <input v-model="conf.nombre" type="text" name="nombre" id="nombre" class="form-control"
                                :disabled="conf.id">
                        </div>
                    </div>
                    <div class="col-md-12 my-3">
                        <label for="valor">Valor <strong class="text-danger"> *</strong></label>
                        <input v-model="conf.valor" type="text" name="valor" id="valor" class="form-control">
                    </div>

                    <div class="col-md-12 my-3">
                        <label for="tipo">Tipo <strong class="text-danger"> *</strong></label>
                        <select v-model="conf.tipo" name="tipo" id="tipo" class="form-control">
                            <option value="string">Texto plano</option>
                            <option value="numeric">Numeric</option>
                            <option value="number">Número</option>
                            <option value="array">Array</option>
                            <option value="object">Objeto</option>
                            <option value="boolean">Booleano</option>
                        </select>
                    </div>

                    <div class="col-md-12 my-3">
                        <label for="descripcion">Descripción <strong class="text-danger"> *</strong></label>
                        <textarea v-model="conf.descripcion" name="descripcion" id="descripcion" cols="30" rows="5"
                            class="form-control" />
                    </div>
                </div>
                <div class="footer">
                    <div class="row">
                        <div class="col-sm-12 text-right">
                            <button type="submit" class="btn btn-primary mr-2">
                              <i class="fa-solid fa-floppy-disk"></i>
                                Guardar
                            </button>
                            <button class="btn btn-danger" type="button" @click.prevent="$emit('close')">
                              <i class="fa-solid fa-xmark"></i>
                              Cancelar
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </Modal>
    </div>
</template>

<script>
import Modal from '@/components/Modal';
import apiCondusef from '@/apps/condusef/api/configuracion';


export default {
    components: { Modal },
    props: {
        configuracion: {
            type: Object
        }
    },
    data() {
        return {
            conf: {
                nombre: null,
                valor: null,
                tipo: 'string',
                descripcion: null
            },
        }
    },
    mounted() {
        if (this.configuracion)
            this.conf = this.$helper.clone(this.configuracion);
    },
    methods: {
        async onSubmit() {
            try {
                let response = null;
                if (!this.conf.id) {
                    response = (await apiCondusef.crear_configuracion(this.conf)).data
                    this.$helper.showMessage('Crear Configuración', 'Configuración guardada exitosamente.', 'success', 'alert')
                }
                else {
                    response = (await apiCondusef.actualizar_configuracion(this.conf.id, this.conf)).data
                    this.$helper.showMessage('Editar Configuración', 'Configuración actualizada exitosamente.', 'info', 'alert')
                }
                this.$log.info('response', response);
                this.$emit('update');
            } catch (error) {
                this.$log.error('error', error);
                this.$helper.showAxiosError(error, 'Error');
            }
        }
    }
};
</script>